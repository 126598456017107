import React from "react"

import Layout       from "../components/share/layout"
import Header       from "../components/Header/upcc"
import WallNews     from "../components/upcc/wall-news"
import WallGallery  from "../components/upcc/wall-gallery"
import WallPrograms from "../components/upcc/wall-programs"
import WallPodcasts from "../components/upcc/wall-podcasts"
import WallSchedule from "../components/upcc/wall-schedule"
import Footer       from "../components/share/footer"

import img   from "../images/upcc/art.png"
// import text  from "../images/upcc/text.svg"
import text  from "../images/upcc/text.png"
import plane from "../images/upcc/plane.png"

export default () => {

  return (
    <Layout
      title="UPC Cultural | Universidad Peruana de Ciencias Aplicadas"
      metaTitle="UPC Cultural | Universidad Peruana de Ciencias Aplicadas"
      description="Bienvenido a UPC Cultural, un espacio de promoción, difusión y discusión del arte y la cultura que nace en la Universidad Peruana de Ciencias Aplicadas."
      subtitile="Bienvenido a UPC Cultural, un espacio de promoción, difusión y discusión del arte y la cultura que nace en la Universidad Peruana de Ciencias Aplicadas."
      metaDescripcin="Bienvenido a UPC Cultural, un espacio de promoción, difusión y discusión del arte y la cultura que nace en la Universidad Peruana de Ciencias Aplicadas."
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      keywords="upc cultural, cultural upc, centro cultural upc"
    >
      {/*<Header/>*/}

      <div class="upcc-container">

        <div class="banner">
          <div class="upcc-inner">
            <div>
              {/*<h1>Bienvenidos al nuevo UPC Cultural</h1>

              <h2>+ Arte</h2>
              <h2>+ Experiencias</h2>
              <h2>+ Cultura</h2> <br/><br/>*/}
              {/*<h1>+ Arte y Cultura</h1>

              <h2>+ Espacios</h2>
              <h2>+ Experiencias</h2> <br/><br/>*/}
              <img src={text} className="img-text" /> <br/><br/>

              <a class="upcc-button" href="#more" >Ver más</a> <br/><br/><br/>

              {/*<img src={plane} />*/}
            </div>

            <div>
              {/*<img class="img" src={img} />*/}
              <div id="more"></div>
            </div>
          </div>
        </div>

        <WallNews/>

        <WallGallery/>

        <WallPrograms/>

        <WallPodcasts/>

        <WallSchedule/>

      </div>

      <Footer/>
    </Layout>
  )
}
